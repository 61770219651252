import { graphql } from '../generated-gql';

export const mutationUpdateCoupon = graphql(`
  mutation CouponUpdate($input: CouponUpdateInput) {
    couponUpdate(input: $input) {
      id
      isActive
      redemptions
    }
  }
`);
